export default Object.freeze({
  type: {
    INIT: 0, // 系統類型：剛註冊完畢時
    CONDITION_UPGRADE: 1, // 業主定義，會員存款，下注紀錄的條件 (依據存款次數與總額設定條件)
    INFO_COMPLETE: 2 // 資料完成：玩家資料填寫完成
  },
  modifierType: {
    MEMBER: "member", // 前台會員修改資料自動升等
    OPERATOR: "operator", // 後台台會員修改資料自動升等 or 後台批次調整會員等級
    SYSTEM: "system" // 系統自動升等
  },
  validation: {
    PAYMENT: 'member.paymentVerification',
  },
});
