const AES = require("crypto-js/aes");
const ENC_UTF8 = require("crypto-js/enc-utf8");

const options = {
  mode: require("crypto-js/mode-ecb"),
  padding: require("crypto-js/pad-pkcs7"),
}

const CRYPTOJSKEY = 'ocms';

export const cryptoUtil = {
  encrypt(plaintText) {
    const key = ENC_UTF8.parse(CRYPTOJSKEY);
    const encryptedData = AES.encrypt(plaintText, key, options);
    return encryptedData.toString();
  },
  decrypt(encryptedBase64Str) {
    const key = ENC_UTF8.parse(CRYPTOJSKEY);
    const decryptedData = AES.decrypt(encryptedBase64Str, key, options);
    return decryptedData.toString(ENC_UTF8);
  },
}