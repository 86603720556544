// Copied from ocms-ap/constants/financial/rewardPointRedeem.js
export default Object.freeze({
  type: {
    PRODUCT: 1,
    CREDIT: 2
  },
  redeem: {
    status: {
      UNPROCESS: 0,
      SUCCESS: 1,
      REJECT: 2,
      WAIT_VERIFY: 3
    }
  }
});
