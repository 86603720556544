import Decimal from 'decimal.js';

function add(a, b) {
  if (checkNullUndefined(a) && checkNullUndefined(b)) {
    const result = new Decimal(a);
    return result.plus(b).toNumber();
  }
  return 0;
}

function subtract(a, b) {
  if (checkNullUndefined(a) && checkNullUndefined(b)) {
    const result = new Decimal(a);
    return result.minus(b).toNumber();
  }
  return 0;
}

function multiply(a, b) {
  if (checkNullUndefined(a) && checkNullUndefined(b)) {
    const result = new Decimal(a);
    return result.times(b).toNumber();
  }
  return 0;
}

function divide(a, b) {
  if (checkNullUndefined(a) && checkNullUndefined(b)) {
    const result = new Decimal(a);
    return result.div(b).toNumber();
  }
  return 0;
}

function checkNullUndefined(val) {
  return (val !== undefined && val !== '' && val !== null && isNumber(val));
}

function isNumber(val) {
  return !Number.isNaN(Number(val));
}

let decimalObj = {
  add,
  subtract,
  multiply,
  divide
};
export default decimalObj;
