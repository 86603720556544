import store from '@/store';

const requestWithLoading = async (cb, ...arg) => {
  store.commit('app/SET_ISLOADING', true);
  const res = await cb(...arg)
    .catch((err) => {
      store.commit('app/SET_ISLOADING', false);
      throw new Error(err);
    });
  store.commit('app/SET_ISLOADING', false);
  return res;
};

export default requestWithLoading;
