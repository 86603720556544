// copy from ocms-ap/constants
export default Object.freeze({
  status: {
    DEPRECATED: -1,
    ENABLED: 1,
    DISABLED: 0,
  },
  deviceType: {
    ALL: -1,
    PC: 0,
    MOBILE: 1,
  },
  devicePlatform: {
    WEB: 'web',
    ANDROID: 'android',
    IOS: 'ios',
  },
  language: {
    ZH_HANT: 1,
    ZH_HANS: 2,
    EN: 3,
    TH: 4,
    ES: 5,
    HI: 6,
    TA: 7,
    MY: 8,
    ID: 9,
    VI: 10,
    'zh-Hant': 1,
    'zh-Hans': 2,
    en: 3,
    th: 4,
    es: 5,
    hi: 6,
    ta: 7,
    my: 8,
    id: 9,
    vi: 10,
    bn: 11,
    ms: 12,
    tl: 13,
    jp: 14,
    km: 15,
    ur: 16,
    pt: 17,
  },
  visible: {
    VISIBLE: 1,
    INVISIBLE: 0,
  },
  view: {
    UNREAD: 0,
    READ: 1,
  },
  thailandBank: {
    id: {
      KBANK: 2,
    },
  },
  bankListType: {
    PAYMENT: 'payment',
    DIRECTA24: 'directa24',
  },
  IMAGE_ACCEPT_TYPE: 'image/jpg,image,image/png,image/webp',
  customerService: {
    VIBER: 'viber',
    ZENDESK: 'zendesk',
    TAWK: 'tawk',
    JIVO: 'jivo',
    RESPONDIO: 'respondio',
    LIVE_CHAT: 'livechat',
    FB: 'fb',
  },
});
