module.exports = {
  type: {
    URL: 0,
    HTML: 1,
  },
  status: {
    ENABLE: 1,
    MAINTENANCE: 2,
    BET_LEVEL: 3,
  },
};
