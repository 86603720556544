export const html = `
  <style>
    body {
      background: rgb(0, 0, 0);
    }
    .container {
      background: rgb(0, 0, 0);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center
    }
    .logo {
      width: 200px;
      animation-name: loading;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
    @keyframes loading {
      0% { transform: scale(1) }
      50% { transform: scale(1.1) }
      100% { transform: scale(1) }
    }
  </style>
  <div class="container">
    <img class="logo" src="logoSrc" alt="logo" />
  </div>
`;

export default html;
