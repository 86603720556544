// copy from ocms-ap/constants/websiteMail

export default Object.freeze({
  mailType: {
    SYSTEM: 1, // 系統公告
    PROMOTION: 2, // 活動
    PERSONAL: 3, // 個人
    FINANCIAL: 4, // Financil Operations: Deposit & Withdrawal
    BONUS: 5,
  },
  type: {
    SYSTEM: 'system', // 系統公告
    PROMOTION: 'action', // 活動
    PERSONAL: 'personal', // 個人
    BONUS: 'bonus',
  },
});
