// copy from ocms-ap/constants/game/gameTag
const gameTagName = Object.freeze({
  ALL: 'all',
  FAVORITE: 'FAVORITE',
  PROMOTE_GAMES_1: 'promoteGames1',
  PROMOTE_GAMES_2: 'promoteGames2',
  HOT_GAME: 'hotGame',
  TOP50_PREFIX: 'top50',
});

export default Object.freeze({
  ...gameTagName,
  type: {
    SYSTEM_DEFINED: 0,
    CUSTOMIZED: 1,
    SYSTEM_CONTROLLED: 2,
    SYSTEM_DONOT_SHOWGAMELIST: 3,
  },
  sorts: {
    [gameTagName.ALL]: [
      {
        columnName: 'status',
        orderType: 'DESC',
      },
      {
        columnName: 'betLevel',
        orderType: 'ASC',
      },
      {
        columnName: 'isNew',
        orderType: 'DESC',
      },
      {
        columnName: 'isHot',
        orderType: 'DESC',
      },
      {
        columnName: 'playCount',
        orderType: 'DESC',
      },
    ],
    [gameTagName.PROMOTE_GAMES_1]: [
      {
        columnName: 'status',
        orderType: 'DESC',
      },
      {
        columnName: 'betLevel',
        orderType: 'ASC',
      },
      {
        columnName: 'playCount',
        orderType: 'DESC',
      },
    ],
    [gameTagName.PROMOTE_GAMES_2]: [
      {
        columnName: 'status',
        orderType: 'DESC',
      },
      {
        columnName: 'betLevel',
        orderType: 'ASC',
      },
      {
        columnName: 'playCount',
        orderType: 'DESC',
      },
    ],
    DEFAULT: [
      {
        columnName: 'status',
        orderType: 'DESC',
      },
      {
        columnName: 'betLevel',
        orderType: 'ASC',
      },
      {
        columnName: 'gameOrder',
        orderType: 'ASC',
      },
    ],
  },
});
