export default Object.freeze({
  // TC 版 GameClass.Type 與公版不同
  type: {
    // TC 定義
    OTHER: 1,
    CASINO: 2,
    SPORT: 3,
    LOTTERY: 4
    // 公版定義，與 TC 版定義互斥，所以不使用
    // SLOT: 2,
    // LIVE: 3,
    // SPORT: 4,
    // FISHER: 5,
    // LOTTERY: 6
  },
  subType: {
    ALL: 0,
    SLOT: 1,
    ETABLE: 2,
    LIVE: 4,
    ARCADE: 5,
    FISHER: 6,
    BAR: 7,
    SPORT: 8,
    BACCARAT: 10,
    DRAGONTIGER: 12,
    ROULETTE: 13,
    SICBO: 15,
    LOTTERY: 30,
    COCK_FIGHTING: 46,
    CRYPTO_CURRENCY: 44,
    APP: 101,
    LOTTO_THAILAND: 102,
    LOTTO_VIETNAM: 103,
    LOTTO_LAOS: 104,
    EXCHANGE: 105,
    CARD_GAMES: 3,
    HORSE_RACING: 45,
    FINANCIAL: 43
  },
  platform: {
    status: {
      MAINTENANCE_BY_GAME: 2,
      ENABLE: 1,
      MAINTENANCE: 0,
      DISABLE: -1
    }
  }
});
