/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validEnAndNum(str) {
  return /^[a-zA-Z0-9]*$/g.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validTrueName(str) {
  return !/[0-9`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/g.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validExcSpecChar(str) {
  return /^[a-zA-Z0-9\u4e00-\u9fa5\u0E00-\u0E7F]*$/g.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validChinese(str) {
  return /^[\u4e00-\u9fa5]*$/g.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validEnglish(str) {
  return /^[a-zA-Z]+$/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validDecimal(str) {
  return /^[0-9]+.[0-9]{0,2}$/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isNumberOrEnglish(str) {
  return /^[0-9A-z]+$/.test(str);
}

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin',
    'editor',
    'topagent'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp|http):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}

/**
 * @param {String} country
 * @param {Number} Phone
 * @returns {Boolean}
 */
export function validePhone(country, phone) {
  let phones = {
    'ar-DZ': /^(\+?213|0)(5|6|7)\d{8}$/,
    'ar-SY': /^(!?(\+?963)|0)?9\d{8}$/,
    'ar-SA': /^(!?(\+?966)|0)?5\d{8}$/,
    'en-US': /^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/,
    'cs-CZ': /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    'de-DE': /^(\+?49[ \.\-])?([\(]{1}[0-9]{1,6}[\)])?([0-9 \.\-\/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/,
    'da-DK': /^(\+?45)?(\d{8})$/,
    'el-GR': /^(\+?30)?(69\d{8})$/,
    'en-AU': /^(\+?61|0)4\d{8}$/,
    'en-GB': /^(\+?44|0)7\d{9}$/,
    'en-HK': /^(\+?852\-?)?[569]\d{3}\-?\d{4}$/,
    'en-IN': /^(\+?91|0)?[789]\d{9}$/,
    'en-NZ': /^(\+?64|0)2\d{7,9}$/,
    'en-ZA': /^(\+?27|0)\d{9}$/,
    'en-ZM': /^(\+?26)?09[567]\d{7}$/,
    'en-th': /^(\+?66\-?|0)+[689]\d{8}$/,
    'es-ES': /^(\+?34)?(6\d{1}|7[1234])\d{7}$/,
    'fi-FI': /^(\+?358|0)\s?(4(0|1|2|4|5)?|50)\s?(\d\s?){4,8}\d$/,
    'fr-FR': /^(\+?33|0)[67]\d{8}$/,
    'he-IL': /^(\+972|0)([23489]|5[0248]|77)[1-9]\d{6}/,
    'hu-HU': /^(\+?36)(20|30|70)\d{7}$/,
    'it-IT': /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
    'ja-JP': /^(\+?81|0)\d{1,4}[ \-]?\d{1,4}[ \-]?\d{4}$/,
    'ms-MY': /^(\+?6?01){1}(([145]{1}(\-|\s)?\d{7,8})|([236789]{1}(\s|\-)?\d{7}))$/,
    'nb-NO': /^(\+?47)?[49]\d{7}$/,
    'nl-BE': /^(\+?32|0)4?\d{8}$/,
    'nn-NO': /^(\+?47)?[49]\d{7}$/,
    'pl-PL': /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
    'pt-BR': /^(\+?55|0)\-?[1-9]{2}\-?[2-9]{1}\d{3,4}\-?\d{4}$/,
    'pt-PT': /^(\+?351)?9[1236]\d{7}$/,
    'ru-RU': /^(\+?7|8)?9\d{9}$/,
    'sr-RS': /^(\+3816|06)[- \d]{5,9}$/,
    'tr-TR': /^(\+?90|0)?5\d{9}$/,
    'vi-VN': /^(\+?84|0)?((1(2([0-9])|6([2-9])|88|99))|(9((?!5)[0-9])))([0-9]{7})$/,
    'zh-CN': /^(\+?0?86\-?)?1[345789]\d{9}$/,
    'zh-TW': /^(\+?886\-?|0)?9\d{8}$/
  };
  if (phones[country]) {
    let regex = new RegExp(phones[country]);
    return regex.test(phone);
  }
}

export function check24hr(minDate, maxDate) {
  let oneTime = new Date().setTime(new Date(minDate).getTime());
  let twoTime = new Date().setTime(new Date(maxDate).getTime());
  if (oneTime + 3600 * 1000 * 24 < twoTime) {
    return false;
  }
  return true;
}

export function check7day(minDate, maxDate) {
  let oneTime = new Date().setTime(new Date(minDate).getTime());
  let twoTime = new Date().setTime(new Date(maxDate).getTime());
  if (oneTime + 3600 * 1000 * 24 * 7 < twoTime) {
    return false;
  }
  return true;
}

export function check30day(minDate, maxDate) {
  let oneTime = new Date().setTime(new Date(minDate).getTime());
  let twoTime = new Date().setTime(new Date(maxDate).getTime());
  if (oneTime + 3600 * 1000 * 24 * 30 < twoTime) {
    return false;
  }
  return true;
}

export function check60day(minDate, maxDate) {
  let oneTime = new Date().setTime(new Date(minDate).getTime());
  let twoTime = new Date().setTime(new Date(maxDate).getTime());
  if (oneTime + 3600 * 1000 * 24 * 60 < twoTime) {
    return false;
  }
  return true;
}

export function check90day(minDate, maxDate) {
  let oneTime = new Date().setTime(new Date(minDate).getTime());
  let twoTime = new Date().setTime(new Date(maxDate).getTime());
  if (oneTime + 3600 * 1000 * 24 * 90 < twoTime) {
    return false;
  }
  return true;
}

/**
 * 依據地區ＩＤ驗證電話正則
 * @param LocationID
 * @param phone
 * @returns {boolean}
 */
export function validateCellPhone(LocationID, phone) {
  let phones = {
    0: /^\d+$/,
    1: /^(\+?66\-?|0)[689]\d{8}$/, // TH 泰國
    2: /^(\+?84|0)[1-9]{1}[0-9]{8}$/, // VI 越南
    3: /^(\+?95|0)9[0-9]{7,9}$/, // MY 緬甸
    4: /^(\+?82|0)10[0-9]{8}$/, // KO 韓國
    5: /^(\+?60|0)[1][0-9]{8,9}$/ // MR 馬來西亞
  };
  if (phones[LocationID]) {
    let regex = new RegExp(phones[LocationID]);
    return regex.test(phone);
  }
}

/**
 * 依據地區ＩＤ驗證銀行帳號正則
 * @param LocationID
 * @param bankAccount
 * @returns {boolean}
 */
export function validateBank(LocationID, bankAccount) {
  let banks = {
    0: /^\d+$/,
    1: /^[0-9]{10,12}$/, // TH 泰國
    2: /^[0-9]{6,18}$/, // VI 越南
    3: /^[0-9]{9}$|^[0-9]{11}$|^[0-9]{16,17}$/, // MY 緬甸
    4: /^\d+$/, // KO 韓國
    5: /^[0-9]{10,16}$/ // MR 馬來西亞
  };

  if (banks[LocationID]) {
    let regex = new RegExp(banks[LocationID]);
    return regex.test(bankAccount);
  }
}

/**
 * 過濾Emoji表情符號
 * @param str
 * @returns {boolean}
 */
export function validateEmoji(str) {
  if (/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g.test(str)) {
    return false;
  }
  return true;
}

/**
 * 英數字 and 至少包含1個數字
 * @param str
 * @returns {boolean}
 */
export function validateContainLeastNumber(str) {
  return /^(?=.*[a-zA-Z])(?=.*\d)[^]{6,12}$/g.test(str);
}

export function validateBirthday(str) {
  return /^(19|20)\d{2}-(1[0-2]|0?[1-9])-(0?[1-9]|[1-2][0-9]|3[0-1])$/g.test(str);
}

/**
 * 6 ~ 12 字元 至少包含一個英文和一個數字
 * @param str
 * @returns {boolean}
 */
export function validateContainLeastNumberAndLetter(str) {
  return /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{6,12}$/g.test(str);
}
