import i18n from '@/language';
import { validateContainLeastNumberAndLetter } from '../../../validate';

const LoginAccount = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value);
  },
  params: [],
  message: () => i18n.t('Message.Msg.AccountFormat')
};

const LoginPassword = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value);
  },
  params: [],
  message: () => i18n.t('Message.Msg.PwdFormat')
};

export {
  LoginAccount,
  LoginPassword
};
