import gameClassConstants from '~/utils/constant/game/gameClass';

function sortPlatform(plat, type) {
  let notMaintenance = [];
  let maintenance = [];
  let notMaintenanceSort;
  let maintenanceSorted;

  switch (type) {
    case 'cashWallet':
      plat.forEach((item) => {
        // 判斷品牌是否有維護
        if (item.isActive === true) {
          notMaintenance.push(item);
        } else {
          maintenance.push(item);
        }
      });
      // 排序沒有維護中的各品牌金額
      notMaintenanceSort = notMaintenance.sort((a, b) => b.balance - a.balance);
      // 已排序過沒有維護的品牌＋維護中的品牌
      maintenanceSorted = maintenance.sort((a, b) => a.status - b.status).sort((a, b) => b.balance - a.balance);
      break;
    case 'promotionWallet':
      plat.forEach((item) => {
        // 判斷品牌是否有維護
        if (item.status === gameClassConstants.platform.status.ENABLE) {
          notMaintenance.push(item);
        } else {
          maintenance.push(item);
        }
      });
      // 排序沒有維護中的各品牌金額
      notMaintenanceSort = notMaintenance.sort((a, b) => b.cashAmount - a.cashAmount);
      // 已排序過沒有維護的品牌＋維護中的品牌
      maintenanceSorted = maintenance.sort((a, b) => a.status - b.status).sort((a, b) => b.cashAmount - a.cashAmount);
      break;
    default:
      throw new Error(`There is currently no wallet of this type.`);
  }
  return notMaintenanceSort.concat(maintenanceSorted);
}
export default sortPlatform;
