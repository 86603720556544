// copy from ocms-ap/constants/member
// copy from ocms-ap-tc/service/member/member
export default Object.freeze({
  registerMethod: { // TC 註冊方式
    ADMIN_ADD: 0,
    MEM_SIGN_UP: 1,
    MEM_QUICK_SIGN_UP: 2,
  },
  defaultPassword: {
    CUSTOM: 0,
    BANK_ACCOUNT: 1,
  },
  signUpFunc: {
    NOT_AVAILABLE: 0,
    NORMAL: 1,
    MAINTENANCE: 2,
    WITH_QUICK_SIGN_UP: 3,
  },
  fieldConfig: {
    verification: {
      MEMBER_AGE_LIMIT: 'memberAgeLimit',
    },
  },
  loginSetting: {
    ACCOUNT: 'SYSTEMREGISTER_ACCOUNT',
    PHONE: 'SYSTEMREGISTER_PHONELOGIN',
    GOOGLE: 'SYSTEMREGISTER_GOOGLE',
    FACEBOOK: 'SYSTEMREGISTER_FACEBOOK',
    LINE: 'SYSTEMREGISTER_LINE',
  },
  avatar: {
    default: 'profile/avatar-default.webp',
    avatar01: 'profile/avatar-01.webp',
    avatar02: 'profile/avatar-02.webp',
    avatar03: 'profile/avatar-03.webp',
    avatar04: 'profile/avatar-04.webp',
    avatar05: 'profile/avatar-05.webp',
  },
  passwordType: {
    NEED_CHANGE_PASSWORD: 0,
    NORMAL: 1,
  },
});
