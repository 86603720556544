export default Object.freeze({
  channelName: {
    Lobby: 'chatRoom.name.lobby',
    NotifyBonus: 'chatRoom.name.bonusNotify',
    DM: 'chatRoom.room.dm'
  },
  room: {
    DM: 'DM',
    LOBBY: 'Lobby',
    NOTIFY_BONUS: 'NotifyBonus',
  },
  channel: {
    LOBBY: 'lobby',
    NOTIFY: 'notify',
    SYSTEM: 'system',
    DM: 'DM',
  },
  bonusType: {
    1: 'WIN', // 贏分
    2: 'RATE', // 倍率
  },
  receiveType: {
    HISTORY: 'HISTORY',
    NEW: 'NEW',
  },
  subTab: {
    CHANNEL: 'channel',
    DM: 'dm',
  },
  messageCountLimit: {
    lobby: 100,
    notify: 100,
    system: 100,
    DM: Infinity,
  },
  updateType: {
    APPEND: 'append',
    PREPEND: 'prepend',
  },
});
