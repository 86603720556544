import common from '~/utils/common';

let eventSource;
const startSSE = () => {
  console.log('startSSE')
  if (eventSource instanceof EventSource && eventSource.readyState !== 2) {
    eventSource.close();
  }
  eventSource = new EventSource(`/service/event?authorization=${common.getLocalStorageValue('Authorization')}&refreshToken=${common.getLocalStorageValue('RefreshToken')}`);
  return eventSource;
}

const endSSE = () => {
  if (eventSource instanceof EventSource && eventSource.readyState !== 2) {
    eventSource.close();
  }
}

export { startSSE, endSSE };
