import axios from 'axios';

import store from '@/store';

import router from '@/router';

import { Message } from 'element-ui';

import i18n from '@/language';

import { template, isEqual } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import APIModel from '~/utils/APIModel';
import common from '~/utils/common';
import { currencySymbol, toFixedMoney } from '~/filters';

const map = [];

const SYSTEM_ERROR_CODES = ['UnknownError', 'common.data.abnormal'];

const instanse = axios.create({
  baseURL: '/',
  timeout: 3 * 1000 * 60,
});

instanse.interceptors.request.use(
  async (config) => {
    const result = { ...config };
    result.headers['Content-Type'] = 'application/json';

    const authorization = common.getLocalStorageValue('Authorization');
    if (authorization) {
      result.headers.common.Authorization = authorization;
    }
    const refreshToken = common.getLocalStorageValue('RefreshToken');
    if (refreshToken) {
      result.headers.common.Refreshtoken = refreshToken;
    }

    const currency = common.getSessionStorageValue('ocms-currency');
    if (currency) {
      result.headers.common['ocms-currency'] = currency;
    }

    const deviceToken = common.getLocalStorageValue('DeviceToken');
    if (deviceToken) {
      result.headers.common['device-token'] = deviceToken;
    }

    result.headers.common['req-trace-key'] = uuidV4();

    if (store.state.initData?.agentId) {
      result.headers.common['agent-id'] = store.state.initData?.agentId;
    }

    return result;
  },
  (error) => {
    console.log('error=====>', error);
    return Promise.reject(error);
  },
);

instanse.interceptors.response.use(
  async (response) => {
    // reset token when response header has it
    const headers = response.headers;
    // 若有夾帶新的token 就覆寫至localStorage
    const authorization = headers.authorization;
    if ((authorization !== undefined) && common.getLocalStorageValue('Authorization') !== authorization) {
      common.setLocalStorageValue('Authorization', authorization);
      store.dispatch('member/setToken', authorization);
    }
    const refreshtoken = headers.refreshtoken;
    if ((refreshtoken !== undefined) && common.getLocalStorageValue('RefreshToken') !== refreshtoken) {
      common.setLocalStorageValue('RefreshToken', refreshtoken);
    }
    const deviceToken = headers['device-token'];
    if (deviceToken) {
      common.setLocalStorageValue('DeviceToken', deviceToken);
    }

    const responseData = new APIModel(response.data);
    if (!responseData.isSuccess && !response.headers['content-type'].includes('image')) {
      Message.error(i18n.t(responseData.code));
      return Promise.reject(responseData);
    }
    if (response.headers['content-type'].includes('image')) {
      return response;
    }
    return responseData;
  },
  async (response) => {
    const { response: error } = response;
    switch (error.status) {
      case 401:
        store.dispatch('logout');
        common.setLocalStorageValue('Authorization', null);
        common.setLocalStorageValue('RefreshToken', null);
        router.push('/');
        store.dispatch('app/setDrawer', { show: true, from: '401' });
        return Promise.reject(response);
      case 404:
        router.push('/');
        store.commit('SET_MAINTAIN_INFO', {
          ...store.state.maintainInfo,
          forceMaintain: true,
        });
        return Promise.reject(response);
      default:
        break;
    }
    if (error?.data?.code) {
      let message = i18n.t(error.data.code);
      if (/\$\{([^\\}]*(?:\\.[^\\}]*)*)\}/g.test(message)) {
        // 有參數需要轉換
        if (error.data.data.amount) {
          error.data.data.amount = currencySymbol(toFixedMoney(error.data.data.amount));
        }
        if (error.data.data?.time?.unit) {
          const time = error.data.data.time;
          error.data.data.period = time.period;
          error.data.data.unit = i18n.t(`common.${time.unit}`);
        }
        message = template(message)(error.data.data);
      }
      if (SYSTEM_ERROR_CODES.includes(error.data.code)) {
        message = template(i18n.t('common.system.error.unknownError'))({ code: error.data.traceKey });
      } else if (!error.config?.headers?.NoTraceKey) {
        const traceMsg = error.data.traceKey ? `\nErrorCode: ${error.data.traceKey}` : '';
        message = `${message}${traceMsg}`;
      }
      Message.warning(message);
    }
    return Promise.reject(response);
  },
);

const service = {
  ...instanse,
  async post(...arg) {
    let findItem = map.find((f) => isEqual(f, arg));
    if (findItem) {
      return new Promise((resolve, reject) => setTimeout(async () => {
        resolve(await service.post(...arg));
      }, 1000));
    }

    map.push(arg);

    const result = await instanse.post(...arg).catch((err) => {
      let findItemIndex = map.findIndex((m) => isEqual(m, arg));
      map.splice(findItemIndex, 1);
      return Promise.reject(err);
    });

    let findItemIndex = map.findIndex((m) => isEqual(m, arg));
    map.splice(findItemIndex, 1);
    // 判斷是否為reject
    if (result.status === undefined || result.status === null) {
      // 正常resolve
      return result;
    }
    // reject
    return Promise.reject(result);
  },
};

export default service;
