// FIXME 找時間改成 常數全大寫的寫法 順便改寫成 Object
import common from './common';
import financial from './financial';
import mail from './mail';
import promotion from './promotion';
import report from './report';

// common status
export const StatusEnable = 1;
export const StatusDisable = 0;

// gameClassType
export const OtherGameClassType = 1;
export const CasinoGameClassType = 2;
export const SportGameClassType = 3;
export const LotteryGameClassType = 4;

// gameClassSubType TC-859 用 SubType 開始當GameClass unique
export const SlotGameClassSubType = 1;
export const ETableGameClassSubType = 2;
export const LiveGameClassSubType = 4;
export const FisherGameClassSubType = 6;
export const ArcadeGameClassSubType = 7;
export const SportGameClassSubType = 8;
export const BaccaratGameClassSubType = 10;
export const DragonTigerGameClassSubType = 12;
export const RouletteGameClassSubType = 13;
export const SicboGameClassSubType = 15;
export const LotteryGameClassSubType = 30;
export const CryptoGameClassSubType = 44;
export const AppGameClassSubType = 101;
export const ThailandLottoClassSubType = 102;
export const VietnamLottoClassSubType = 103;
export const LaosLottoClassSubType = 104;

// gameKind
export const SlotGameKind = 1;
export const LiveGameKind = 4;
export const FisherGameKind = 6;
export const ArcadeGameKind = 7;
export const SportGameKind = 8;
export const BaccaratGameKind = 10;
export const DragonTigerGameKind = 12;
export const RouletteGameKind = 13;
export const SicboGameKind = 15;

// game tag name (game select type)
export const GameTagPromoteGames1 = 'promoteGames1';
export const GameTagPromoteGames2 = 'promoteGames2';
export const GameTagHotGame = 'hotGame';

// mail type
export const SystemMailType = 1;
export const ActionMailType = 2;
export const PersonalMailType = 3;
export const FinancialMailType = 4;

// promotion import bonus
export const PromotionImportDetailStatusFinished = 1;
export const PromotionImportDetailStatusExecuting = 2;
export const PromotionImportDetailStatusDraw = 3;
export const PromotionImportDetailStatusExpired = 4;
export const PromotionImportDetailStatusError = 5;

// promotionInfo type
export const PromotionInfoTagTypeSystem = 0;
export const PromotionInfoTagTypeCustom = 1;
export const PromotionInfoTagTypeVoucherCode = 1001;

// indexpage gamelist block count
export const DefaultPromoteGameMaxCount = 24;

// game launch type
export const GameLaunchTypeURL = 0;
export const GameLaunchTypeHTML = 1;

// voucherCode
export const VoucherCodeRepeatType = 1;
export const VoucherCodeNonRepeatType = 0;

// deviceType
export const DesktopDeviceType = 0;
export const MobileDeviceType = 1;
export const PWADeviceType = 4;
export const InAppBrowserDeviceType = 3;

// payment methods
export const PaymentMethodForward = 1;
export const PaymentMethodQRCODE = 2;
export const PaymentMethodWAP = 3;
export const PaymentMethodWithdraw = 4;
export const PaymentMethodManualDeposit = 5;
export const PaymentMethodVirtualPayChannel = 6;
export const PaymentMethodAutoCashIn = 7;
// payment method device
export const PaymentMethodDeviceAll = 1;
export const PaymentMethodDeviceWeb = 2;
export const PaymentMethodDeviceApp = 3;
// popup
export const PopupTypeGame = 1;
export const PopupTypePromotion = 2;
export const PopupTypeEvent = 3;
export const PopupEventTypeNone = 0;
export const PopupEventTypeInternalLink = 1;
export const PopupEventTypeExternalLink = 2;
// webcontent
export const WebContentNameDeposit = 'deposit';
export const WebContentNameWithdraw = 'withdraw';

export default {
  common,
  financial,
  mail,
  promotion,
  report
};
