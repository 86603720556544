import { isAfter, parseISO } from 'date-fns';
import { template } from 'lodash';
import i18n from '@/language';
import { validateEmoji } from '../../../validate';

const emoji = {
  validate(value, {}) {
    return validateEmoji(value);
  },
  params: [],
  message: () => i18n.t('member.error.nickname.emoji')
};

const strlength = {
  validate(value, { min, max }) {
    console.log(min, max);
    return value.length >= min && value.length <= max;
  },
  params: ['min', 'max'],
  message: (fieldName, { min, max, _value_ }) => {
    if (Number(min) === 0) {
      min = 1;
    }
    if (Number(max) === 0) {
      max = 9999999;
    }

    if (Number(min) !== 0) {
      if (Number(_value_) < Number(min)) {
        return i18n.t('common.errored.amount.cannotLessThan', { Amount: min });
      }
    }
    if (Number(max) !== 0) {
      if (Number(_value_) > Number(max)) {
        return i18n.t('common.errored.amount.cannotGreaterThan', { Amount: max });
      }
    }
  }
};

const required = {
  validate(value, {}) {
    try {
      value = value.toString();
      return !!value;
    } catch (e) {
      return false;
    }
  }
};

const IntegerOnly = {
  validate(value, {}) {
    return /^[0-9]*$/g.test(value);
  },
  params: [],
  message: () => `${i18n.t('common.format')} ${i18n.t('common.error')}`
};

const ContactPhone = {
  validate(value, {}) {
    return /^[0-9]{10,11}$/g.test(value);
  },
  params: [],
  message: () => i18n.t('gcms.error.contactPhone.format'),
};

const AgeLimit = {
  validate: (value, [minAgeLimit]) => isAfter(parseISO(minAgeLimit), parseISO(value)) || minAgeLimit === value,
  message: () => 'common.register.age.min.limit',
};

const Digits = {
  params: ['digits'],
  validate: (value, { digits }) => /^[0-9]*$/.test(value) && value.length === +digits,
  message: (field, { digits }) => template(i18n.t('common.fields.error.digits'))({ field, digits }),
};

export {
  emoji,
  strlength,
  required,
  IntegerOnly,
  ContactPhone,
  AgeLimit,
  Digits,
};
