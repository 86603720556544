import {
  SlotGameKind,
  LiveGameKind,
  FisherGameKind,
  ArcadeGameKind,
  SportGameKind,
  BaccaratGameKind,
  DragonTigerGameKind,
  RouletteGameKind,
  SicboGameKind,
} from '~/utils/constant';

export const LiveGameKindCondition = gameKindId => /^(4|1[0-9]|20)$/.test(gameKindId);
export const SportGameKindCondition = gameKindId => gameKindId == SportGameKind;