const Common = {
  // set value to LocalStorag
  setLocalStorageValue(name, value) {
    if (name === undefined) return;

    const newValue = typeof value === 'object' ? JSON.stringify(value) : value;

    localStorage.setItem(name, newValue);
  },

  removeLocalStorageKey(name) {
    if (name === undefined) return;

    localStorage.removeItem(name, name);
  },

  // get value from LocalStorag
  getLocalStorageValue(name) {
    let value;
    try {
      value = JSON.parse(localStorage.getItem(name));
    } catch (error) {
      value = localStorage.getItem(name);
    }

    return value;
  },

  setSessionStorageValue(name, value) {
    if (name === undefined) return;

    sessionStorage.setItem(name, JSON.stringify(value));
  },

  removeSessionStorageKey(name) {
    if (name === undefined) return;

    sessionStorage.removeItem(name, name);
  },

  getSessionStorageValue(name) {
    const value = sessionStorage.getItem(name);
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  },

  // 取網址的params
  locationResearch() {
    // 取網址的params
    const pairs = window.location.search.substring(1).split('&');
    const obj = {};
    let pair;
    let i;

    for (i in pairs) {
      if (pairs[i] === '') {
        continue;
      }

      pair = pairs[i].split('=');
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return obj;
  },

  /**
   * 字串 轉 hex
   * @param {string} str - 字串
   * @returns {string} hex
   */
  str2hex(str) {
    let hexString = '';
    for (let index = 0; index < str.length; index++) {
      let hex = str.charCodeAt(index).toString(16);
      if (hex.length < 2) {
        hex = hex.padStart(2, '0');
      }
      hexString += hex;
    }
    return hexString;
  },

  // base64 encode
  b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      (match, p1) => String.fromCharCode(`0x${p1}`)));
  },

  // base64 decode
  b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
  },

  timeOutWinLocation(path) {
    if (!path) return;
    setTimeout(() => {
      window.location = path;
    }, 300);
  },

  isEgaming() {
    return window && window.process && window.process.type;
  },

  // object tree step to one step
  flattenObject(object, separator = '.') {
    const isValidObject = (value) => {
      if (!value) {
        return false;
      }

      const isArray = Array.isArray(value);
      const isObject = Object.prototype.toString.call(value) === '[object Object]';
      const hasKeys = !!Object.keys(value).length;

      return !isArray && isObject && hasKeys;
    };

    const walker = (child, path = []) => Object.assign({}, ...Object.keys(child).map((key) => (isValidObject(child[key])
      ? walker(child[key], path.concat([key]))
      : { [path.concat([key]).join(separator)]: child[key] })));

    return { ...walker(object) };
  },

  // 取得url參數
  getUrlParam(name) {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
    const r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
};

export default Common;
