import i18n from '@/language';
import { validateContainLeastNumberAndLetter } from '../../../validate';

const RegisterAccount = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value);
  },
  params: [],
  message: () => i18n.t('Message.Msg.AccountFormat'),
};

const RegisterPassword = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value);
  },
  params: [],
  message: () => i18n.t('Message.Msg.PwdFormat'),
};

const Register_ReferrerAccount = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value);
  },
  params: [],
  message: () => i18n.t('common.format') + i18n.t('common.error'),
};

const RegisterConfirmPassword = {
  validate: (confirmPassword, [password]) => confirmPassword === password,
  message: () => i18n.t('Auth_Signup.errored.passwordConfirm'),
};

export {
  RegisterAccount,
  RegisterPassword,
  Register_ReferrerAccount,
  RegisterConfirmPassword,
};
