class APIModel {
  data = {}

  code = ''

  isSuccess = false

  constructor(data) {
    this.data = data.data;
    this.code = data.code;
    this.isSuccess = data.code === 'common.success';
  }
}

export default APIModel;
