import i18n from '@/language';
import { extend, localize } from 'vee-validate';
import localeEN from 'vee-validate/dist/locale/en.json';
import localeTW from 'vee-validate/dist/locale/zh_TW.json';
import localeCN from 'vee-validate/dist/locale/zh_CN.json';
import localeTH from 'vee-validate/dist/locale/th.json';
import customMessages from './customMessages.json';

const locales = {
  en: localeEN,
  my: localeEN,
  th: localeTH,
  'zh-Hans': localeCN,
  'zh-Hant': localeTW,
};

/**
 * 建立自訂 vee-validate rule
 * @param {string} name
 * @param {string} pattern
 * @returns {boolean}
 */
export const createRule = (name, pattern) => extend(name, (value) => new RegExp(pattern).test(value));

/**
 * 深度搜尋 obj 中的 pattern
 * @param { Record<string, any> } obj
 * @param { string | undefined } parent
 * @returns {void}
 */
export const extractPatterns = (obj, parent) => {
  if (obj.pattern !== undefined) return createRule(parent, obj.pattern);

  for (const key in obj) {
    if (typeof obj[key] === 'object') extractPatterns(obj[key], key);
  }
};

/**
 * 擴充 vee-validate 的錯誤訊息
 */
export const extendMessages = (lang) => {
  const fields = {};
  for (const root in customMessages) {
    fields[root] = {};
    for (const key in customMessages[root]) {
      const value = i18n.t(customMessages[root][key]);
      if (key === 'regex') fields[root][root] = value;
      else fields[root][key] = value;
    }
  }

  const { messages, ...other } = locales[lang];

  localize('en', {
    ...other,
    messages: {
      ...messages,
      required: i18n.t('common.shortErrorMsg.required'),
    },
    fields,
  });
};
