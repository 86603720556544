import { validator } from 'agmp-utility';
import store from '@/store';
import i18n from '@/language';
import { validateContainLeastNumberAndLetter } from '../../../validate';

const Account = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value);
  },
  params: [],
  message: () => i18n.t('Message.Msg.AccountFormat')
};

const PhoneNumber = {
  validate(value, {}) {
    return validator.validPhone(store.getters.LocationCode, value);
  },
  params: [],
  message: () => `${i18n.t('common.format')} ${i18n.t('common.error')}`
};

export {
  Account,
  PhoneNumber
};
