// copy from ocms-ap/constants/promotion
// promotionImportDetail

export default Object.freeze({
  info: {
    tagType: {
      SYSTEM: 0,
      CUSTOM: 1,
      VOUCHER_CODE: 1001,
    },
  },
  // promotion import bonus
  importDetail: {
    status: {
      FINISHED: 1, // 已完成
      EXECUTING: 2, // 執行中
      DRAW: 3, // 已領取
      EXPIRED: 4, // 已過期
      ERROR: 5, // 錯誤
    },
  },
  spinAndWin: {
    ticketType: {
      LOGIN: 'login',
      REGISTER: 'register',
      DEPOSIT: 'deposit',
      TURNOVER: 'turnover',
    },
    ticket: {
      STATUS_UNUSED: 0,
      STATUS_USED: 1,
      STATUS_REJECTED: 2,
      STATUS_EXPRIED: 3,
    },
  },
  referral: {
    type: {
      MONTHLY: 1,
      WEEKLY: 2,
      DAILY: 3,
    },
  },
  // db: typeof promotionId === 'Number'，型態不要混用
  promotionInfoTagIdType: {
    NONE: -99,
    ALL: -1,
  },
  promotionInfoTagCode: {
    NONE: 'NONE',
    ALL: 'ALL',
    BRAND_DAY: 'Brand Day',
    CASH_PROMOTIONS: 'Cash Promotions',
    NEW_MEMBER_ONLY: 'New Member Only',
    HISTORY: 'History',
    VOUCHER_CODE: 'Voucher Code',
  },
  buttonType: {
    JOIN: 'join',
    RELEASE: 'release',
  },
  tournament: {
    tabType: {
      CURRENT: 'CURRENT',
      HISTORY: 'HISTORY',
    },
  },
  record: {
    itemName: {
      normal: 'common.promotion.promotion', // 綁定型參加型活動 - 優惠活動
      mission: 'promotion.missionCenter.dailyMission', // 任務型活動 - 每日任務
      luckyDraw: 'financial.feedback.LotteryReward', // 非綁定型參加型活動 - 抽獎活動
      dragonRank: 'common.promotion.dragonRank', // 龍榜的標籤，用以搜尋這個活動。
      TigerRank: 'common.promotion.tigerRank', // 虎榜的標籤，用以搜尋這個活動。
      LotteryReward: 'financial.feedback.LotteryReward', // 抽獎的標籤，用以搜尋這個活動。
      DailyReward: 'financial.feedback.DailyRewardV2', // 返水plugin:日返水的標籤，用以搜尋這個活動。
      WeeklyReward: 'financial.feedback.CommonWeeklyRewardV2', // 返水plugin:週返水的標籤，用以搜尋這個活動。
      DailyLossReward: 'financial.feedback.DailyLossReward', // 返水plugin:日輸分返水的標籤，用以搜尋這個活動。
      WeeklyLossReward: 'financial.feedback.WeeklyLossReward', // 返水plugin:週輸分返水的標籤，用以搜尋這個活動。
      ReceiveDailyReward: 'financial.feedback.ReceiveDailyReward', // 返水plugin:可領取日返水的標籤，用以搜尋這個活動。
      RollingReward: 'financial.feedback.Type', // 返水類型活動標籤
      DailyPoint: 'financial.feedback.CommonDailyPointV2', // 日返點
      WeeklyPoint: 'financial.feedback.CommonWeeklyPointV2', // 週返點
      RollingPoint: 'financial.feedback.PointType', // 返點類型活動標籤
      DailyTask: 'promotion.missionCenter.dailyMission', // 每日任務的子任務
      ActivityTask: 'financial.feedback.ActivityTask', // 每日任務中的活躍度活動
      BonusGame: 'vue.promotion.bonusGame', // 遊戲贈金標籤
      'Monthly Member Referrals': 'financial.feedback.RecommendedWeeklyReward', // 月老帶新的預設名稱
      'Weekly Member Referrals':
        'financial.feedback.RecommendedRealWeeklyReward', // 週老帶新的預設名稱
      'Daily Member Referrals': 'financial.feedback.RecommendedDailyReward', // 日老帶新的預設名稱
      'Weekly Point': 'financial.feedback.CommonWeeklyPointV2', // 週返點活動名稱
      'Daily Point': 'financial.feedback.CommonDailyPointV2', // 日返點活動名稱
      'Weekly Loss Reward': 'financial.feedback.WeeklyLossReward', // 週輸分返水活動名稱
      'Daily Loss Reward': 'financial.feedback.DailyLossReward', // 日輸分返水活動名稱
      'Real-Time Reward': 'financial.feedback.ReceiveDailyReward', // 可領取日返水(即時反水)活動名稱
      'Referral Bonus': 'member.referral.bonus', // 推薦獎金活動名稱
      Tournament: 'promotion.tournament.title', // 錦標賽
      'online deposit': 'vue.common.store', // 在線存款
      'Payment Shop': 'common.giftPackage', // APP內購
      'reward withdraw point': 'common.financial.tradeType_29', // 兌換消點
    },
  },
});
