import { isAfter } from 'date-fns';

export const getListFormat = (maxLength, list, modNum) => {
  const listLength = list?.length;
  const nullLength = maxLength - listLength;

  for (const item of list) {
    item.imgUrl = item.imageData?.imageMap?.['500x500'] ?? item.imageData?.imageUrl;
  }

  if (nullLength === 0) { return list; }
  if (nullLength < 0) { return list.slice(0, maxLength); }

  for (let i = 1; i <= nullLength; i++) {
    let nullItem = { isEmpty: true };

    if (modNum) {
      nullItem.weekNumber = Math.floor((maxLength - i) / modNum) + 1;
    }

    list.push(nullItem);
  }
  return list;
};

export const countDailyWinner = (list) => Object.entries(list)
  .sort((a, b) => {
    let scoreRank = b[1].highScore - a[1].highScore;
    let timeRank = isAfter(b[1].betTime, a[1].betTime);

    return scoreRank == 0 ? timeRank : scoreRank;
  })
  .reduce((accum, item, index, sortArray) => {
    let sortValue = sortArray[index][1];
    accum.push(sortValue);
    return accum;
  }, [])[0];

export default {
  getListFormat,
  countDailyWinner,
};
