import i18n from '@/language';
import { validateContainLeastNumberAndLetter } from '../../../validate';

const NewPassword = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value);
  },
  params: [],
  message: () => i18n.t('Message.Msg.PwdFormat')
};

export {
  NewPassword
};
