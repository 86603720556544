import gameClass from './gameClass';
import gameTag from './gameTag';
import launch from './launch';

export default {
  gameClass,
  gameTag,
  launch,
  DEFAULT_PROMOTION_GAME_MAX_COUNT: 24,
  status: {
    MAINTENANCE: 0,
    ENABLE: 1,
  },
};
