import common from './common';

(() => {
  const vc = common.getUrlParam('vc');
  const mid = common.getUrlParam('mid');
  const cid = common.getUrlParam('cid');
  const pid = common.getUrlParam('pid');
  const domain = window.document.location.hostname;

  if (vc) {
    common.setLocalStorageValue('vc', vc);
    common.removeLocalStorageKey('memberId');
    common.removeLocalStorageKey('cId');
    common.removeLocalStorageKey('pid');
  } else if (mid) {
    common.setLocalStorageValue('memberId', mid);
    common.removeLocalStorageKey('vc');
    common.removeLocalStorageKey('cId');
    common.removeLocalStorageKey('pid');
  } else if (cid) {
    common.setLocalStorageValue('cId', cid);
    common.removeLocalStorageKey('memberId');
    common.removeLocalStorageKey('vc');
    common.removeLocalStorageKey('pid');
  } else if (pid) {
    common.setLocalStorageValue('pid', pid);
    common.removeLocalStorageKey('memberId');
    common.removeLocalStorageKey('vc');
    common.removeLocalStorageKey('cId');
  }

  common.setLocalStorageValue('domain', domain);
  // Store.dispatch('actionRootStateChange', {
  //   domain
  // })
})();
