import {
  DesktopDeviceType,
  MobileDeviceType,
  PWADeviceType,
  InAppBrowserDeviceType,
} from './constant';

export const mobileDetect = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const detectDevice = () => {
  if (window.matchMedia('(display-mode: fullscreen)').matches || window.navigator.standalone === true) return PWADeviceType;
  if (/Line/.test(navigator.userAgent)) return InAppBrowserDeviceType;
  return mobileDetect() ? MobileDeviceType : DesktopDeviceType;
};
