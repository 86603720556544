// copy from ocms-ap/constants
export default Object.freeze({
  payment: {
    type: {
      FORWARD: 1,
      QRCODE: 2,
      WAP: 3,
      WITHDRAW: 4,
      MANUL_DEPOSIT: 5,
      VIRTUAL_PAY_CHANNEL: 6,
      AUTO_CASH_IN: 7,
      RECOUPMENT: 8,
    },
    methodType: {
      WECHAT_QRCODE: 1,
      ALIPAY_QRCODE: 2,
      BANK: 3,
      QQ_QRCODE: 4,
      PREPAY_T0: 5,
      PREPAY_T1: 6,
      IDENTIFY: 7,
      QUICK: 8,
      QQ_WAP: 9,
      JD_QRCODE: 10,
      WECHAT_WAP: 11,
      SKRILL: 12,
      CREDIT: 13,
      ALIPAY_WAP: 14,
      JD_WAP: 15,
      UNIONPAY_QRCODE: 16,
      YIYOU85_COIN: 17,
      NETELLER: 18,
      WECHAT_TRANSFER: 19,
      UNIONPAY_WAP: 20,
      WECHAT_BARCODE: 21,
      // CREDITCARD: 22, // FIXME 已回報金流Team 等待修正
      BARCODE: 23,
      CREDITCARD: 24,
      BANK_QRCODE: 25,
      QPAY: 26,
      MOMO: 27,
      ZALO: 28,
      VIETTEL: 29,
      PREPAID_CARD: 30,
      UPI: 31,
      GATEWAY: 32,
      ASTROPAY: 33,
      BKASH: 37,
      ROCKET: 38,
      NAGAD: 39,
      WAVE_MONEY_TRANSFER: 40,
      SPITZER: 44,
    },
    device: {
      ALL: 1,
      PC: 2,
      APP: 3,
    },
    categoryName: {
      GOOGLE: 'GOOGLE_PAY',
      APPLE: 'APPLE_PAY',
    },
  },
  transfer: {
    status: {
      PENDING: 0,
      SUCCESS: 1,
      FAIL: 2,
      FORCED_RETURN: 3,
    },
  },
  deposit: {
    status: {
      UNPROCESS: 0, // 未審核
      SUCCESS: 1, // 成功
      REJECT: 2, // 拒絕
      FORCE_SUCCESS: 3, // 強制成功
      FORCE_REJECT: 4, // 強制失敗
      EXCEPTION: 5, // 異常
      WAIT_VERIFY: 6, // 待審核
    },
    merchant: {
      priorityType: {
        RANDOM: 0,
        CUSTOM: 1,
      },
    },
  },
  withdraw: {
    status: {
      UNPROCESS: 0,
      SUCCESS: 1,
      REJECT: 2,
      WAIT_VERIFY: 3, // 3 回歸待審核
      PREPAY_PROCESSING: 4,
      EXCEPTION: 5,
      AUTO_PROCESSING: 6,
      AUTO_PROC_FAILED: 7,
      AUDIT_SUCCESS: 8,
      WAIT_PAY: 9, // 9定義改為待支付
    },
  },
  recordSearchDate: {
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    LAST_SEVEN_DAY: 'lastSevenday',
    CURRENT_MONTH: 'currentMonth',
    LAST_MONTH: 'lastMonth',
    LAST_WEEK: 'lastWeek',
  },
  // following copy from ocms-ap/service/member/money/record/index.js
  record: {
    type: {
      ALL: 0,
      DEPOSIT: 1,
      WITHDRAW: 2,
      RECOUPMENT: 3,
      REDEEM_DIAMOND: 4,
      PROMOTION_HISTORY: 5,
    },
    tradeType: {
      onlineDeposit: 10,
      promotionPoint: 21,
    },
  },
  appleStore: {
    purchaseResult: {
      SUCCESS: 'success',
      NOT_FOUND: 'notFound',
      CANCELED: 'canceled',
      FAILED: 'failed',
    },
  },
  socketEventType: {
    deposit: 1, // 存款
    promotion: 2, // 贈金
    dm: 3, // 私訊
  },
});
