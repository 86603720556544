import i18n from '@/language';
import store from '@/store';
import { validator } from 'agmp-utility';
import {
  validateBirthday,
  validEmail,
  validateEmoji,
  validTrueName
} from '../../../validate';

const DetailInfo_Name = {
  validate(value, {}) {
    return validTrueName(value);
  },
  params: [],
  message: () => `${i18n.t('common.format')} ${i18n.t('common.error')}`
};

const DetailInfo_Birthday = {
  validate(value, {}) {
    return validateBirthday(value);
  },
  params: [],
  message: () => `${i18n.t('common.format')} ${i18n.t('common.error')}`
};

const DetailInfo_IDNumber = {
  validate(value, {}) {
    return validateEmoji(value);
  },
  params: [],
  message: () => `${i18n.t('common.format')} ${i18n.t('common.error')}`
};

const DetailInfo_Phone = {
  validate(value, {}) {
    return validator.validPhone(store.getters.LocationCode, value);
  },
  params: [],
  message: () => `${i18n.t('common.format')} ${i18n.t('common.error')}`
};

const DetailInfo_Verify = {
  validate(value, {}) {
    return /^[0-9]{6,6}$/g.test(value);
  },
  params: [],
  message: () => `${i18n.t('common.format')} ${i18n.t('common.error')}`
};

const DetailInfo_BankAccount = {
  validate(value, {}) {
    return validator.validBankAccount(store.getters.LocationCode, value);
  },
  params: [],
  message: () => `${i18n.t('common.format')} ${i18n.t('common.error')}`
};

const DetailInfo_Email = {
  validate(value, {}) {
    return validEmail(value);
  },
  params: [],
  message: () => `${i18n.t('common.format')} ${i18n.t('common.error')}`
};

export {
  DetailInfo_Name,
  DetailInfo_Birthday,
  DetailInfo_IDNumber,
  DetailInfo_Phone,
  DetailInfo_Verify,
  DetailInfo_BankAccount,
  DetailInfo_Email
};
