export default Object.freeze({
  taskType: {
    // 壓碼量
    BET_AMOUNT: 1,
    // 贏分
    NET_WIN: 2,
    // 存款金額
    DEPOSIT_AMOUNT: 3,
    // 活躍度
    ACTIVITY: 4,
    // 存款次數
    DEPOSIT_COUNT: 5,
    // 每日登入次數
    LOGIN_TIMES: 6,
    // 淨輸贏
    POSITIVE_NET_WIN: 7,
  },
  rewardType: {
    // 獎金
    AMOUNT: 1,
    // 點數
    POINT: 2,
    // 活躍點數
    ACTIVITY: 3,
  },
});
